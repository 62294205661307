<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :v-else-if="1"
      loader="dots"
    ></Loading>
    <div>
      <div style="padding: 0.4em">
        <div class="main">
          <div class="log-section">
            <div class="d-flex justify-content-between mb-3">
              <p class="count">
                {{
                  $t("asset.log_amount", {
                    amount: totalRows,
                  })
                }}
              </p>
            </div>
            <div
              class="log-container"
              v-for="log in assetLog"
              :key="log.log_asset_id"
            >
              <p
                class="log-detail"
                style="cursor: pointer;"
                @click="gotoLogDetail(log.id_asset)"
                v-if="log.type === 'แก้ไขที่ดิน'"
              >
                {{ log.created_at | formatDate }}, {{ $t("asset.as_num") }}:{{
                  log.id_fixed_asset
                }}, {{ $t("depreciation.edit_land_by") }}
                {{ log.user }}
              </p>
              <p
                class="log-detail"
                style="cursor: pointer;"
                @click="gotoLogDetail(log.id_asset)"
                v-if="log.type === 'ลบที่ดิน'"
              >
                {{ log.created_at | formatDate }}, {{ $t("asset.as_num") }}:{{
                  log.id_fixed_asset
                }}, {{ $t("depreciation.delete_land_by") }} {{ log.user }}
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <b-pagination
              v-if="totalRows > 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import moment from "moment";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import { baseUrl } from "../../util/backend";
import { authHeader } from "../../store/actions";

export default {
  data() {
    return {
      isLoading: false,
      searchText: "",
      filterOptions: {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      },
      assetLog: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 20,
    };
  },
  components: {
    Loading,
  },
  watch: {
    selected() {
      console.log(this.selected == this.position[5].id_permission);
    },
    currentPage() {
      // this.$router.push({
      //   path: this.$route.path,
      //   query: { page: this.currentPage },
      // });
      this.getLog();
    },
  },
  computed: {
    roleFilterArr() {
      let choice = [...new Set(this.role_choice)];
      // console.log("filter role",choice.length,this.role_choice)

      return choice[0] === undefined ? ["ไม่พบข้อมูล"] : choice;
    },
    optionPosition() {
      return this.position
        .map((position) => {
          return position.text;
        })
        .filter((x) => x);
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY - HH:mm:ss");
    },
  },
  methods: {
    handleclear() {
      this.filterOptions = {
        department: "ทั้งหมด",
        role: "ทั้งหมด",
      };
    },

    gotoLogDetail(idAsset) {
      this.$router.push(`/depreciation/landlogdetail/${idAsset}`);
    },
    setFilter() {
      this.userSearchResult = this.userFilter;
    },
    resetFilter() {
      this.filterOptions.department = "ทั้งหมด";
      this.filterOptions.role = "ทั้งหมด";
    },

    async getLog() {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}asset/getlandlog/${id_company}?limit=${
            this.perPage
          }&page=${this.currentPage}`,
          authHeader()
        );
        this.assetLog = res.data.data;
        this.totalRows = res.data.count;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
    },
  },

  mounted() {
    // if (this.$route.query.page) {
    //   this.currentPage = this.$route.query.page || 1;
    // }
    this.getLog();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}
input {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
select {
  border-radius: 10px !important;
}

.position-box {
  background: #fdfdfd;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-back {
  top: 15px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  // margin-bottom: 24px;
  position: absolute;
}

.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  display: grid;
  gap: 2rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
  }
}

.sub-menu {
  color: $font-grey;
}

.input-group {
  // width: 100%;
  display: flex;
  // padding: 0;
}
.custom-control custom-radio {
  height: max-content;
  align-self: center;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.filter-box {
  background-color: #e0e0e0;
}

.log-section {
  width: 85%;
  text-align: left;
  margin: 20px auto 0 auto;
  .log-container {
    border-bottom: 1px solid #c4c4c4;
    padding: 10px 0px;
  }
  .log-detail {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 12px;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  // border: 1px solid #007AFE;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.table {
  font-size: 14px;
  width: 100%;
}

.table-header {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  // border-radius: 30px;
  // background-color: #007AFE;
  color: white;
  th {
    font-weight: 400;
  }
  th:first-child {
    border-radius: 30px 0 0 0px;
  }
  th:last-child {
    border-radius: 0 30px 0px 0;
  }
}
// .table:last-child {
//   border-radius: 30px 30px 0 0;
// }

.edit-pencil {
  cursor: pointer;
}

.btn-lost {
  font-size: 13px;
  padding: 0.5rem;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

button.cancle {
  width: 120px;
  height: 35px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

.btn-disabled {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #cecece 0%, #7c7c7c 100%) 0%
    0% no-repeat padding-box;
  cursor: not-allowed !important;
}

button.confirm {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%) 0%
    0% no-repeat padding-box;
}

.confirm-btn {
  width: 120px;
  height: 40px;
  margin: 10px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: transparent linear-gradient(180deg, #fe0000 0%, #810101 100%) 0%
    0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .card-form {
    height: fit-content;
    padding-top: 30px;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    overflow: hidden;

    button {
      margin-top: 40px;
    }
  }
}
.modal {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.del-icon {
  height: 50px;
  margin-bottom: 15px;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

.admin {
  width: 100%;
  // background: transparent linear-gradient(180deg, #013f81 0%, #007afe 100%) 0%
  //   0% no-repeat padding-box;
}

@media only screen and (min-width: 1025px) {
  .menu-admin {
    display: none;
  }
}

@media only screen and (max-width: 1025px) {
  .close-min1025 {
    display: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
